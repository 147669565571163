/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value
*
*   PROPS
*   value:  numeric value
*   label: string
*   loading: boolean to toggle loading animation (optional)
*   icon: icon to use (optional)
*   change: positive/negative number indicating change amount (optional)
    cardClass: class to apply to card (optional)
*
**********/

import { Card, Icon, Loader, ClassHelper } from "components/lib";
import Style from "./stat.tailwind.js";

export function Stat(props) {
	const changeUp = props.change?.toString().includes("-") ? false : true;

	// is loading
	if (props.loadinbg || props.value === undefined) {
		return (
			<Card className={props.cardClass}>
				<div className={Style.stat}>
					<Loader absoluteStyle={false} />
				</div>
			</Card>
		);
	}

	const statStyle = ClassHelper(Style, {
		stat: true,
		className: props.className,
	});

	const colorsStyle = {
		blue: "bg-blue-500",
		red: "bg-red-500",
		green: "bg-green-500",
		purple: "bg-purple-500",
		orange: "bg-orange-500",
		yellow: "bg-yellow-500",
		pink: "bg-pink-500",
	};

	return (
		<Card className={props.cardClass}>
			<div className={statStyle}>
				{props.icon && (
					<div className={Style.icon}>
						<div
							className={colorsStyle[props.iconColor]}
							style={{
                                position: "absolute",
								height: "30px",
								width: "30px",
								borderRadius: "50%",
                                opacity: "0.1",
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)'
							}}></div>
						<Icon color={props.iconColor} image={props.icon} size={20} />
					</div>
				)}

				<div className={Style.label}>{props.label}</div>
				<div className={Style.valueContent}>
					<div className="flex items-center">
						<span className={Style.value}>{props.value}</span>
						<p className={Style.valueLabel}>{props.valueLabel}</p>
					</div>

					{(props.change > 0 || props.change < 0) && (
						<div className={Style.change}>
							<span>{props.change}</span>

							<Icon
								color={
									changeUp && props.isPositive
										? "green"
										: !props.isPositive && !changeUp
										? "green"
										: "red"
								}
								image={changeUp ? "arrow-up" : "arrow-down"}
								className={Style.changeIcon}
							/>
						</div>
					)}
				</div>
			</div>
		</Card>
	);
}
