const Style = {
    container: 'border border-t-4 flex flex-col bg-white shadow-xl rounded-2xl min-w-[289px] max-w-[290px]  my-3',
    list: 'overflow-y-auto overflow-x-hidden scrollbar px-4 py-2',
    top: 'flex justify-between items-center p-4 pb-1',
    title: 'text-lg font-semibold',
    action: 'flex',
    actionBtn: 'transition duration-300 ease-in-out hover:bg-slate-200 rounded-2xl p-1',
    count: 'text-sm font-semibold bg-gray-50 rounded-full p-1 px-2.5',
};

export default Style;
