export const findTicketStatus = (ticket) => {
	const {
		ticket_category: { status_template },
	} = ticket;

	let ticketStatus = null;
	status_template.forEach((item) => {
		item.statuses.forEach((status) => {
			if (status.id === ticket.integration_data.status) {
				ticketStatus = item;
				return;
			}
		});
	});
	return ticketStatus;
};

export const findCurrStatus = (ticket) => {
	const { integration_data, ticket_category } = ticket;
	const { status } = integration_data;

	let currStatus = null;
	// find valetify status from ticket category status_template
	ticket_category?.status_template.forEach(({ key, statuses }) => {
		const statusFound = statuses.find((item) => item.id === status);
		if (statusFound) {
			currStatus = key;
			return;
		}
	});

	return currStatus;
};

export const findActiveTickets = (tickets) => {
	return tickets?.filter((ticket) => {
		const currStatus = findCurrStatus(ticket);

		// don't count ticket that are archived and not closed
		if (
			(ticket.is_archived && currStatus !== "closed") ||
			(ticket.is_archived && currStatus !== "completed")
		) {
			return false;
		}

		return currStatus !== "closed";
	});
};
