import { Badge } from "components/lib";
import React from "react";
import Style from "./ticket.tailwind";
import { useSearchParams } from "react-router-dom";
import { useTranslate } from "app/translations";

export const priorityColor = {
	urgent: `bg-red-100 text-red-500`,
	high: `bg-orange-100 text-orange-500`,
	medium: `bg-main-100 text-main-500`,
	low: `bg-green-100 text-green-500`,
};

export const TicketItem = (props) => {
	const { _t } = useTranslate();
	let [searchParams, setSearchParams] = useSearchParams();
	const { data, onItemClick } = props;
	const {
		subject,
		text,
		priority,
		due_date,
		is_synced,
		is_archived,
		number,
		ticket_assignee,
		histories,
	} = data;

	const firstAssignee = ticket_assignee[0];
	const assigneeInitials =
		firstAssignee?.first_name?.charAt(0) + firstAssignee?.last_name?.charAt(0) ||
		"";

	const numOfComments = histories.filter((history) => {
		return history.action_type === "comment_posted";
	});

	return (
		<div
			className={Style.container}
			onClick={() => {
				searchParams.set("id", number);
				setSearchParams(searchParams);
				onItemClick && onItemClick();
			}}>
			<div className={Style.top}>
				<span className={Style.text}>{subject || text}</span>
				{(!is_synced || is_archived) && (
					<Badge
						icon="alert-circle"
						sizeIcon={14}
						className={`p-2 px-0 py-0 bg-orange-100 text-orange-500`}
					/>
				)}
			</div>
			<div className={Style.bottom}>
				<div className={Style.info}>
					<Badge
						text={_t(`priority.${priority}`)}
						className={`w-fit py-[2px] text-[10px] ${priorityColor[priority]}`}
					/>
					{assigneeInitials && (
						<Badge
							text={assigneeInitials}
							className={`w-fit py-[2px] text-[10px] bg-white border text-gray-500`}
						/>
					)}
					{numOfComments.length > 0 && (
						<Badge
							icon="message-circle"
                            sizeIcon={10}
							text={numOfComments.length}
							className={`flex items-center py-[2px] text-[10px] bg-white border text-gray-500`}
						/>
					)}
				</div>
				{due_date && (
					<span className={Style.date}>{new Date().toLocaleDateString()}</span>
				)}
			</div>
		</div>
	);
};
