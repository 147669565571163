const Style = {
    container: 'mb-2 relative top-0 min-w-[190px] flex flex-col shadow-md bg-white rounded-2xl border',
    header: 'z-10 p-3',
    title: 'text-md font-semibold text-center', 
    categoryList: 'flex flex-col space-y-2 mb-2 overflow-y-auto overflow-hidden p-2',
    category: 'flex items-center py-2 px-3 text-slate-500 text-md font-semibold w-full transition-all duration-300 rounded-2xl hover:bg-slate-400/10',
    icon: 'text-slate-500/80 cursor-pointer hover:text-slate-500 hover:scale-125',
    btnContent: 'space-y-2 px-2 pb-3',
    btn: 'text-xm px-[2px] py-[7px] w-full',
    count: 'text-sm text-slate-500 mr-2',
};

export default Style;
