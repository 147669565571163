const Style = {

  stat: 'relative text-center h-full mx-2 flex flex-col justify-between',
  icon: 'absolute top-2 right-2 -z-1',
  valueContent: 'flex items-center justify-between',
  value: 'text-2xl font-bold',
  valueLabel: 'text-sm font-light text-slate-400 ml-1',
  change: 'flex items-center justify-between text-sm ml-3 text-slate-500',
  label: 'text-start text-sm text-slate-500 flex flex-wrap max-w-[80%] z-[5]',

}

export default Style;