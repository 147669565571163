import React from "react";
import Style from "./status.tailwind";
import { TicketItem } from "components/lib";
import { useTranslate } from "app/translations";
import "./status.scss";

export const TicketStatus = (props) => {
	const { item } = props;
	const { _t } = useTranslate();

	const filteredTickets = props.tickets.filter((ticket) => {
		return !ticket.is_archived;
	});

	const itemColors = {
		pending: "border-t-gray-300",
		in_progress: "border-t-yellow-300",
		on_hold: "border-t-red-300",
		completed: "border-t-blue-300",
		closed: "border-t-green-300",
	};
    
	return (
		<div className={Style.container + " scrollbar " + itemColors[item.key]}>
			<div className={Style.top}>
				<h3 className={Style.title}>{_t(`ticket.category.status.${item.key}`)}</h3>
				<div className={Style.action}>
					<p className={Style.count}>{filteredTickets.length}</p>
				</div>
			</div>
			<div className={Style.list}>
				{filteredTickets.map((ticket, index) => {
					return <TicketItem key={index} data={ticket} status={item.label} />;
				})}
			</div>
		</div>
	);
};
