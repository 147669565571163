import React, { useEffect } from 'react';
import { Row, Content, Message, ViewContext } from 'components/lib';
import { useTranslate } from 'app/translations';
import { useMutation } from 'react-query';
import { useApiContext } from 'app/api';
import Cookies from 'js-cookie';
import settings from '../settings';

const { client_url_main } = settings;

export function Redirect() {
    const { _t } = useTranslate();
    const { callApi } = useApiContext();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const viewContext = React.useContext(ViewContext);

    const stateParams = state?.split(' ') ?? [];
    let [name, accountId, service, user, redirectUrl] = stateParams;

    if (process.env.NODE_ENV === 'development') {
        const isNgrok = window.location.href.includes('ngrok');

        if (isNgrok) {
            window.location.href =
                process.env.REACT_APP_CLIENT_URL_FULL + '/redirect?code=' + code + '&state=' + state;
        }
    }

    const { mutate: requestToken } = useMutation(
        'integrationToken',
        () =>
            callApi({
                url: '/api/integration/requestToken',
                method: 'post',
                data: { code, service, accountId, name, user },
                auth: false,
            }),
        {
            onSuccess: (data) => {
                console.log('DATA', data)
                if (!data.data.token) return;
                Cookies.set('integration-token', data.token, {
                    expires: 1 / 48,
                    domain: client_url_main,
                });
                window.location.href = redirectUrl + '#integrations';
            },
            onError: (error) => {
                viewContext.notification.show(_t('redirect.error'), 'error', true, 'toast');
            },
        },
    );

    useEffect(() => {
        if (code && redirectUrl) {
            requestToken();
        }
    }, []);

    if (!code || !state) {
        return <Message type="error">{_t('redirect.empty')}</Message>;
    }

    return (
        <Row>
            <Content>
                <h1>{_t('redirect.redirectWait')}</h1>
            </Content>
        </Row>
    );
}
