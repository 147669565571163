import React, { useContext } from "react";
import Style from "./commands.tailwind";
import { useProjectContext, Button, ViewContext, useNavigate } from "components/lib";
import { useTranslate } from "app/translations";
import { AuthContext } from "app/auth";
import { useQueryClient } from "react-query";

const TicketCommands = ({ ticket }) => {
	const viewContext = useContext(ViewContext);
	const navigate = useNavigate();
	const { _t } = useTranslate();
	const { projectUser } = useProjectContext();
	const url = ticket.integration_data.url;
	const queryClient = useQueryClient();

	const {
		user: { permission, user_id },
	} = useContext(AuthContext);

	const canArchive =
		ticket?.user_id === user_id ||
		projectUser?.role === "owner" ||
		projectUser?.role === "admin";

	const onArchive = () => {
		viewContext.modal.show(
			{
				title: _t("ticket.archive.title"),
				form: {},
				buttonText: _t("archive"),
				url: "/api/ticket/" + ticket.id + "/archive",
				method: "PUT",
				destructive: true,
				text: _t("ticket.archive.description"),
			},
			() => {
				// refetch tickets
				queryClient.refetchQueries("tickets");
				viewContext.modal.hide();
				// redirect to /app/tickets
				navigate("/app/tickets");
			},
		);
	};

	return (
		<div className={Style.container}>
			{url && permission !== "contact" && (
				<Button
					text={_t("view.on") + " " + ticket.ticket_category.integration_data.service}
					small
					action={() => window.open(url, "_blank")}
					color="blue"
					className={Style.btn}
				/>
			)}
			{canArchive && (
				<Button
					text={_t("archive")}
					small
					color="red"
					className={Style.btn}
					action={onArchive}
				/>
			)}
		</div>
	);
};

export default TicketCommands;
