import React, { useEffect, useContext, useState } from "react";
import {
	TicketControls,
	TicketSidebar,
	TicketStats,
	TicketStatus,
	useProjectContext,
	Popup,
	Button,
	ViewContext,
	TicketDetails,
} from "components/lib";
import Style from "./tickets.tailwind";
import { useSearchParams, useNavigate } from "react-router-dom";
import { _t } from "app/translations";
import { useLiveQuery } from "components/hooks/websocket";
import { getUserCache } from "utilities/cache";

const Tickets = () => {
	const {
		status: projectsStatus,
		selectedProject,
		isAllProjectSelected,
		projects,
		projectUsers,
	} = useProjectContext();
	// hooks
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const context = useContext(ViewContext);
	const [isRendered, setIsRendered] = useState(false);

	const ticketId = searchParams.get("id");
	const categoryId = searchParams.get("c");
	const sortBy = searchParams.get("by");
	const order = searchParams.get("order");
	const priority = searchParams.get("priority");
	const assignee = searchParams.get("assignee");
	const projectName = searchParams.get("p");
	const onlyMe = searchParams.get("me");
	const creator = searchParams.get("creator");
	const query = searchParams.get("q");

	// variables
	const hasProjects = projectName || projects?.length;
	const { user_id } = getUserCache();

	function updateSearchParams(key, params) {
		// make sure that we are not updating the params if the value is the same
		if (searchParams.get(key) === params) return;
		let newParams = getSearchParams();

		newParams[key] = params;
		newParams = {
			...searchParams,
			...newParams,
		};

		searchParams.set(key, params);
		if (newParams.p === "all") {
			delete newParams.c;
			searchParams.delete("c");
		}

		setSearchParams(searchParams);
	}

	// get all search params
	const getSearchParams = () => {
		let params = {};
		searchParams.forEach((value, key) => {
			params[key] = value;
		});
		return params;
	};

	const ticketCategories = useLiveQuery(
		"ticket_categories",
		{
			project_id: selectedProject?.id,
		},
		{
			enabled: projectsStatus === "success",
		},
	);

	const currCategoryData = ticketCategories?.data?.find((item) => {
		if (!categoryId) return true;

		if(categoryId === "all") return true;
		return item.id === categoryId;
	});
	const creatorId = projectUsers?.find((user) => user.email === creator)?.user_id;

	const allTickets = useLiveQuery(
		"tickets",
		{
			by: sortBy,
			order: order,
			priority: priority,
			assignee: assignee,
			project_id: isAllProjectSelected ? undefined : selectedProject?.id,
			q: query,
			user_id: onlyMe ? user_id : creatorId ? creatorId : undefined,
		},
		{
			enabled: projectsStatus === "success",
		},
	);

	const tickets = useLiveQuery(
		"tickets",
		{
			ticket_category_id: isAllProjectSelected
				? undefined
				: categoryId !== "all"
				? categoryId
				: undefined,
			by: sortBy,
			order: order,
			priority: priority,
			assignee: assignee,
			project_id: isAllProjectSelected ? undefined : selectedProject?.id,
			q: query,
			user_id: onlyMe ? user_id : creatorId ? creatorId : undefined,
		},
		{
			enabled: projectsStatus === "success",
		},
	);
	
	const currTicketData = tickets?.data?.find((item) => {
		return item.number === ticketId;
	});

	// Lifecycle

	// set project name in search params
	useEffect(() => {
		const currProjectName = selectedProject?.name.toLowerCase() || "all";
		updateSearchParams("p", currProjectName);
	}, [selectedProject, ticketId]);

	// Make sure that the there is always a category id and a project name in the search params if
	useEffect(() => {
		if (!isRendered) return;
		if (!ticketId) {
			if (!searchParams.get("p")) {
				const currProjectName = selectedProject?.name.toLowerCase() || "all";
				updateSearchParams("p", currProjectName);
			}
			if (!categoryId && ticketCategories?.data?.length) {
				updateSearchParams("c", "all");
			}
		}
	}, [categoryId, projectName, ticketId, isRendered]);

	// set category params
	useEffect(() => {
		if (
			ticketCategories?.data?.length &&
			ticketCategories.status === "success" &&
			projectName !== "all"
		) {
			// set category params to the first category
			if (!currTicketData && !ticketId) {
				if (!categoryId) {
					updateSearchParams("c", "all");
				}
			} else if (currTicketData) {
				updateSearchParams("c", currTicketData.ticket_category_id);
			}
		}
		setIsRendered(true);
	}, [ticketCategories.data, projectName, currTicketData]);

	useEffect(() => {
		if (projectName !== "all" && ticketCategories?.data?.length) {
			if (!ticketId && !categoryId) {
				updateSearchParams("c", "all");
			}
		}
	}, [selectedProject, ticketCategories?.data]);

	// refresh tickets when category changes
	useEffect(() => {
		tickets.refetch();
		if (!ticketId && categoryId) {
			updateSearchParams("c", categoryId);
		}
	}, [categoryId]);

	useEffect(() => {
		// Make sure that the project name is always the selectedProject name
		if (projectName === "all") {
			updateSearchParams("p", selectedProject?.name.toLowerCase() || "all");
		}
	}, [projectName]);

	// Show ticket details when ticket id is present in search params
	useEffect(() => {
		if (ticketId && projectsStatus === "success") {
			context.pannel.show(
				{
					component: (
						<TicketDetails
							selectedTicket={ticketId}
							category={categoryId}
							selectedProject={selectedProject}
						/>
					),
				},
				() => {
					searchParams.delete("id");
					setSearchParams(searchParams);
				},
			);
		} else {
			context.pannel.hide();
		}
	}, [ticketId, projectName]);

	function sortTicketByStatus(status) {
		const ticketsData = tickets?.data || [];
		if (!ticketsData.length) return [];

		const filteredTickets = ticketsData.filter((ticket) => {
			const {
				ticket_category: { status_template },
				integration_data: { status: ticketStatus },
			} = ticket;

			const categoryStatus = status_template.find((item) => {
				const { statuses } = item;
				return statuses.find((statusItem) => statusItem.id === ticketStatus);
			});

			return categoryStatus.key === status.key;
		});

		return filteredTickets;
	}

	return (
		<div className={Style.container}>
			{selectedProject && (
				<TicketSidebar
					updateSearchParams={updateSearchParams}
					categories={ticketCategories}
					tickets={allTickets?.data || []}
				/>
			)}
			<div className={Style.content}>
				<div className={Style.top}>
					<TicketStats tickets={tickets} />
				</div>
				<TicketControls
					setSearchParams={setSearchParams}
					searchParams={searchParams}
					categoryParams={categoryId}
					getSearchParams={getSearchParams}
				/>
				<div className={Style.status}>
					{currCategoryData?.status_template?.map((item, index) =>
						item.hidden ? null : (
							<TicketStatus
								item={item}
								tickets={sortTicketByStatus(item)}
								key={index}
								label={item.label}
								color={item.color}
								categoryParams={categoryId}
							/>
						),
					)}
				</div>
			</div>
			{/* Render a warning popup in case no projects exist and the organization has completed the integration */}
			<Popup
				show={!hasProjects && projectsStatus === "success"}
				title={_t("error.noProject.title")}
				text={_t("error.noProject.text")}>
				<div className="mt-8">
					<Button
						text={_t("project.goto.multiple")}
						color="blue"
						action={() => navigate("/app/settings/projects")}></Button>
				</div>
			</Popup>
		</div>
	);
};

export default Tickets;
