import { buildQueryParams } from 'utilities/params';

export const liveData = {
    projects: {
        apiUrl: (params) =>
            `/api/projects${buildQueryParams({
                user: params?.user,
            })}`,
        websocketKey: (params) => `projects:${params?.account_id}`,
        type: 'list',
    },
    project_email: {
        apiUrl: (params) => `/api/project/private/field/${params?.project_id}`,
        websocketKey: (params) => `project_email:${params?.project_id}`,
        type: 'list',
    },
    project_domain: {
        apiUrl: (params) => `/api/project/private/field/${params?.project_id}`,
        websocketKey: (params) => `project_domain:${params?.project_id}`,
        type: 'list',
    },
    project_user: {
        apiUrl: (params) => `/api/project-users${buildQueryParams(params)}`,
        websocketKey: (params) => `project_user:${params?.id}`,
        type: 'list',
    },
    ticket_category: {
        apiUrl: (params) => `/api/ticket/category/${params?.id}`,
        websocketKey: (params) => `ticket_categories:${params?.project_id}`,
        type: 'list',
    },
    ticket_categories: {
        apiUrl: (params) =>
            `/api/ticket/category${buildQueryParams({
                project_id: params?.project_id,
            })}`,
        websocketKey: (params) => `ticket_categories:${params?.project_id}`,
        type: 'list',
    },
    tickets: {
        apiUrl: (params) => `/api/tickets${buildQueryParams({
            ticket_category_id: params?.ticket_category_id,
            id: params?.id,
            project_id: params?.project_id,
            by: params?.by,
            order: params?.order,
            priority: params?.priority,
            assignee: params?.assignee,
            number: params?.number,
            q: params?.q,
            user_id: params?.user_id,
        })}`,
        websocketKey: (params) => `tickets:${params?.ticket_category_id}`,
        type: 'list',
    },
};
