/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access. <SubdomainRoute> component to prevent access to website main page
    on subdomain routes.
 *
 **********/

import { useState, useEffect, createContext } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { navigateRoot, navigateSubDomain } from 'utilities/routes';
import { getUserCache, deleteUserCookie, validateUserDomain } from 'utilities/cache';
import { useApiContext } from './api';
import { useQueryClient, useQuery } from 'react-query';
import settings from '../settings';

const { client_url_main } = settings;

// auth context
export const AuthContext = createContext();

const permissions = require('./permissions');

export function AuthProvider(props) {
    const { callApi, postEvent } = useApiContext();
    const [user, setUser] = useState(getUserCache());
    const isEmpty = (obj) => Object.keys(obj).length === 0;
    const queryClient = useQueryClient();

    const userData = useQuery('user', () => callApi({ url: '/api/user' }));

    useEffect(() => {
        if (user && !userData.data?.data && userData.status === 'success') {
            signout();
        } else if (userData.data?.data && !isEmpty(userData.data.data) && !isEmpty(user)) {
            // update the latest user permission data to the storage
            setUser((prevUser) => {
                const newUser = {
                    ...prevUser,
                    permission: userData.data.data.permission,
                };
                localStorage.setItem('user', JSON.stringify(newUser));
                return newUser;
            });
        }
    }, [userData.data]);

    // Queries
    const auth = useQuery(
        'auth',
        // if user call the auth api
        user
            ? () => callApi({ url: '/api/auth' })
            : () =>
                  Promise.resolve({
                      data: null,
                  }),
    );

    useEffect(() => {
        // update the auth status
        if (!auth.isLoading && !isEmpty(auth.data)) {
            const data = auth.data;
            data.authenticated && update(data);
        }
    }, [auth.data, auth.isLoading]);

    function signin(res, accountId, redirectUrl) {
        if (res.data) {
            postEvent('signin');

            const accountSubDomain = res.data.accounts.find((account) => {
                return accountId ? account.id === accountId : account.id === res.data.account_id;
            })?.domain;

            // Set the user cookie
            if (accountSubDomain) {
                Cookies.set('user', JSON.stringify(res.data), {
                    domain: client_url_main,
                    expires: 1 / 48,
                });
            }

            if (!res.data.plan) {
                navigateSubDomain(accountSubDomain, '/signup/plan');
                return;
            }

            const path = redirectUrl
                ? redirectUrl
                : res.data.onboarded
                ? '/app/tickets'
                : '/app/onboarding';
            navigateSubDomain(accountSubDomain, path);
        }
    }

    async function signout() {
        callApi({ method: 'delete', url: '/api/auth' });
        localStorage.clear();
        // Clear the user cookie
        deleteUserCookie();
        navigateRoot('/signin');
    }

    async function switchAccount(id) {
        const res = await callApi({
            method: 'post',
            url: '/api/auth/switch',
            data: { account: id },
        });
        if (res.data) {
            queryClient.invalidateQueries({ queryKey: ['auth'] });
            localStorage.clear();
            signin(res);
        }
    }

    function update(data) {
        const user = getUserCache();
        if (user) {
            for (let key in data) {
                if (Array.isArray(data[key])) {
                    user[key] = data[key];
                } else if (typeof data[key] === 'object') {
                    for (let innerKey in data[key]) {
                        user[key][innerKey] = data[key][innerKey];
                    }
                } else {
                    user[key] = data[key];
                }
            }

            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
        }
    }

    function switchDomain(domain) {
        const currPathname = window.location.pathname;

        const user = getUserCache();

        if (!user) return;

        let newUser = {
            ...user,
        };

        // update accounts domain that has the same id as the account_id
        newUser.accounts = newUser.accounts.map((account) => {
            if (account.id === newUser.account_id) {
                account.domain = domain;
            }
            return account;
        });

        // Add cookie for redirection
        Cookies.set('user', JSON.stringify(newUser), {
            domain: client_url_main,
            expires: 1 / 48,
        });

        // Remove old domain user from local storage
        localStorage.clear();

        // Redirect to the same page with new domain
        navigateSubDomain(domain, currPathname);
    }

    const setUserOnboarded = (onboarded) => {
        const updatedUser = {
            ...user,
            onboarded: onboarded ? 1 : 0,
        };
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
    };

    return (
        <AuthContext.Provider
            value={{
                user: user,
                signin: signin,
                signout: signout,
                update: update,
                switchAccount: switchAccount,
                permission: permissions[user?.permission],
                switchDomain: switchDomain,
                setUserOnboarded: setUserOnboarded,
            }}
            {...props}
        />
    );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
    // check user exists
    const user = getUserCache();
    const path = window.location.pathname;
    const permittedRoutes = [
        '/app/settings/billing',
        '/signup/plan',
        '/app/settings/upgrade',
        '/app/settings',
        '/app/settings/profile',
    ];

    const isOnboarded = user?.onboarded || props.allowNotOnboarded;

    if (user?.token && validateUserDomain(user) && isOnboarded) {
        if (permissions[user.permission][props.permission]) {
            // user has no plan
            if (!user.plan && path !== '/app/settings/profile' && path !== '/signup/plan')
                return <Navigate to="/signup/plan" />;

            // user has no subscription
            if (
                user.subscription !== 'active' &&
                user.subscription !== 'trialing' &&
                user.permission !== 'master' &&
                !permittedRoutes.includes(path)
            )
                return <Navigate to="/app/settings/billing" />;

            // user is good
            return props.children;
        }
    }

    //  user is not authenticated
    navigateRoot('/signin');
}
