import React, { useMemo } from "react";
import { Stat } from "components/lib";
import Style from "./stats.tailwind";
import { useTranslate } from "app/translations";
import * as dayjs from "dayjs";
import { findCurrStatus, findActiveTickets } from "utilities/tickets";
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const calcDiffTime = (date1, date2) => {
	const diffTime = Math.abs(new Date(date1) - new Date(date2)); // Calculate the difference in milliseconds
	const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Calculate the difference in days
	const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Calculate the difference in hours

	return diffDays + diffHours / 24;
};

const isDateOffsetFrom = (date1, date2, offset = 0) => {
	return dayjs(date1).isSame(
		offset > 0 ? dayjs(date2).add(offset, "day") : dayjs(date2).subtract(offset, "day"),
		"day",
	);
};

const isDateOlderThan = (date, offset = 0) => {
	const today = dayjs();
	return dayjs(date).isBefore(today.subtract(offset, "day"));
};

const isDateOffsetSameOrBefore = (date1, date2) => {
	return dayjs(date2).isSameOrBefore(dayjs(date1), "day");
};

const calcAverageCompletionTime = (tickets, offset = 0) => {
	let completedTicketCount = 0;
	const result =
		tickets.data?.reduce((acc, ticket) => {
			let { histories } = ticket;
			const currStatus = findCurrStatus(ticket);

			if (currStatus !== "completed" && currStatus !== "closed") {
				return acc;
			}

			// get histories that are within offset
			histories = histories.filter((history) => {
				const compareDate = offset > 0 ? dayjs().add(Math.abs(offset), "day") : dayjs().subtract(Math.abs(offset), "day");
				
				// Don't count 1 month old histories
				if (isDateOlderThan(history.date_created, 30)) {
					return false;
				}

				return isDateOffsetSameOrBefore(compareDate, history.date_created);
			});

			let statusChangedToCompletedHistory = histories.reverse().find((history) => {
				if (
					(history.action_type === "status" &&
						history.history_data?.after === "completed") ||
					(history.action_type === "status" && history.history_data?.after === "closed")
				) {
					return true;
				} else {
					return false;
				}
			});

			const creationDate = ticket.date_created;
			const completionDate = statusChangedToCompletedHistory?.date_created;

			if (!completionDate) {
				return acc;
			} else {
				completedTicketCount++;
				return acc + calcDiffTime(completionDate, creationDate);
			}
		}, 0) / completedTicketCount;
	return result;
};

const calcAveragePendingTime = (tickets, offset = 0) => {
	let pendingTicketCount = 0;
	const result =
		tickets.data?.reduce((acc, ticket) => {
			let { histories } = ticket;

			if (offset) { 
				// get histories that are within offset
				histories = histories.filter((history) => {
					console.log(history)
					// Don't count 1 month old histories
					if (isDateOlderThan(history.date_created, 30)) {
						return false;
					}

					const compareDate = offset > 0 ? dayjs().add(Math.abs(offset), "day") : dayjs().subtract(Math.abs(offset), "day");
					return isDateOffsetSameOrBefore(compareDate, history.date_created);
				});
			}

			// find history that left pending status
			const history = histories.reverse().find((history) => {
				if (
					history.action_type === "status" &&
					history.history_data?.before === "pending"
				) {
					return true;
				} else {
					return false;
				}
			});

			if (!history) {
				return acc;
			}

			const creationDate = ticket.date_created;
			const pendingDate = history?.date_created;

			if (!pendingDate) {
				return acc;
			} else {
				pendingTicketCount++;
				return acc + calcDiffTime(pendingDate, creationDate);
			}
		}, 0) / pendingTicketCount;
	return result;
};

export const TicketStats = ({ tickets }) => {
	const { _t } = useTranslate();

	const activeTickets = useMemo(() => {
		return findActiveTickets(tickets.data);
	}, [tickets.data]);

	const newTickets = useMemo(() => {
		return activeTickets?.filter((ticket) => {
			const { date_created } = ticket;

			const today = dayjs();
			const ticketDate = dayjs(date_created);
			return isDateOffsetFrom(today, ticketDate);
		});
	}, [tickets.data]);

	const completedTodayTickets = useMemo(() => {
		return tickets.data?.filter((ticket) => {
			const { histories, is_archived } = ticket;
			if (is_archived) return false;

			const currStatus = findCurrStatus(ticket);

			if (currStatus !== "completed" && currStatus !== "closed") {
				return false;
			}

			const todayHistories = histories.filter((history) => {
				return isDateOffsetFrom(dayjs(), history.date_created);
			});

			// find latest history find status update
			const statusChangedHistory = todayHistories.reverse().find((history) => {
				if (
					(history.action_type === "status" &&
						history.history_data?.after === "completed") ||
					(history.action_type === "status" && history.history_data?.after === "closed")
				) {
					return true;
				} else {
					return false;
				}
			});

			return statusChangedHistory;
		});
	}, [tickets.data]);

	const averageCompletionTime = useMemo(() => {
		return calcAverageCompletionTime(tickets) || 0;
	}, [tickets.data]);

	const averageYesterdayCompletionTime = useMemo(() => {
		return calcAverageCompletionTime(tickets, -1) || 0;
	}, [tickets.data]);

	const averagePendingTime = useMemo(() => {
		return calcAveragePendingTime(tickets) || 0;
	}, [tickets.data]);

	const averageYesterdayPendingTime = useMemo(() => {
		return calcAveragePendingTime(tickets, -1) || 0;
	}, [tickets.data]);

	return (
		<section className={Style.container}>
			<Stat
				cardClass={
					"mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2 border min-h-[80px]"
				}
				loading={false}
				label={_t("ticket.stats.number")}
				icon="clipboard"
				iconColor="pink"
				value={activeTickets?.length}
			/>
			<Stat
				cardClass={
					"mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2 border min-h-[80px]"
				}
				loading={false}
				label={_t("ticket.stats.new")}
				icon="plus"
				iconColor="blue"
				value={newTickets?.length}
			/>
			<Stat
				cardClass={
					"mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2 border min-h-[80px]"
				}
				loading={false}
				label={_t("ticket.stats.completedToday")}
				icon="calendar"
				iconColor="green"
				value={completedTodayTickets?.length}
			/>
			<Stat
				cardClass={
					"mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2 border min-h-[80px]"
				}
				loading={isNaN(averageCompletionTime) || isNaN(averagePendingTime)}
				label={_t("ticket.stats.averageCompletion")}
				icon="check"
				iconColor="purple"
				value={averageCompletionTime === 0 ? 0 : averageCompletionTime?.toFixed(2)}
				valueLabel={_t("days")}
				isPositive={false}
				change={(averageCompletionTime - averageYesterdayCompletionTime).toFixed(2)}
			/>
			<Stat
				cardClass={
					"mx-2 shadow mb-2 w-full flex-none lg:flex-1 w-[150px] p-2 border min-h-[80px]"
				}
				label={_t("ticket.stats.averagePending")}
				loading={isNaN(averageCompletionTime) || isNaN(averagePendingTime)}
				icon="clock"
				iconColor="orange"
				value={averagePendingTime === 0 ? 0 : averagePendingTime?.toFixed(2)}
				valueLabel={_t("days")}
				isPositive={false}
				change={(averagePendingTime - averageYesterdayPendingTime).toFixed(2)}
			/>
		</section>
	);
};
