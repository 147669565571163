import React, { useEffect, cloneElement } from 'react';
import { SubNav, useBreadcrumbsContext } from 'components/lib';
import { _t } from 'app/translations';
import { useParams } from 'react-router-dom';
import { useLiveQuery } from 'components/hooks/websocket';
import { useProjectContext } from 'components/lib';

const TicketsCategoryLayout = (props) => {
    const { id } = useParams();
    const { pushParams, setForcedActiveUrl } = useBreadcrumbsContext();
    const { selectedProject } = useProjectContext();

    // get category data
    const categoryData = useLiveQuery(
        'ticket_category',
        { id, project_id: selectedProject?.id },
        {
            enabled: !!selectedProject?.id && !!id,
        },
    );

    let data = categoryData?.data ? categoryData.data[0] : {};
    const baseUrl = `/app/tickets/category/${id}`;

    const containUnassigned = data?.status_template?.[0]?.statuses?.length > 0;

    const items = [
        { link: `${baseUrl}`, label: _t('general'), permission: 'admin', exact: true },
        // { link: `${baseUrl}/form`, label: _t('form'), permission: 'owner' },
        // { link: `${baseUrl}/email`, label: _t('email.label'), permission: 'owner' },
        {
            link: `${baseUrl}/status`,
            label: _t('status.multiple'),
            permission: 'user',
            ...(containUnassigned ? { alert: { message: 'You have unassigned statuses' } } : {}),
        },
    ];

    useEffect(() => {
        pushParams(
            {
                id: data?.name,
            },
            'id',
        );
        setForcedActiveUrl(`/app/tickets/category/${id}`);
    }, [data?.name]);

    return (
        <div>
            <SubNav items={items} />
            {cloneElement(props.children, { data })}
        </div>
    );
};

export default TicketsCategoryLayout;
