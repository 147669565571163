/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import {
    Animate,
    ViewContext,
    Button,
    Form,
    Card,
    Link,
    Row,
    Message,
    SocialSignin,
    useLocation,
    useNavigate,
} from 'components/lib';
import { AuthContext } from 'app/auth';
import { useTranslate } from 'app/translations';

export function Signin(props) {
    const { _t } = useTranslate();

    // context
    const authContext = useContext(AuthContext);
    const viewContext = useContext(ViewContext);
    const location = useLocation();
    const navigate = useNavigate();

    // state
    const [useMagic, setUseMagic] = useState(false);
    const [magicLinkSent, setMagicLinkSent] = useState(false);
    const [form, setForm] = useState({
        email: {
            label: _t('email.label'),
            type: 'email',
            required: true,
        },
        password: {
            label: _t('password.label'),
            type: 'password',
            required: true,
        },
        forgotpassword: {
            type: 'link',
            url: '/forgotpassword',
            text: _t('password.forgot.question'),
        },
    });

    useEffect(() => {
        // was an error message passed from the server router?
        const qs = location.search;

        if (qs.includes('error')) {
            const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=') + 7));
            viewContext.notification.show(msg, 'error');
        }
    }, [location.search, viewContext.notification]);

    function toggleMagicLink(useMagic) {
        const f = { ...form };
        f.password.type = useMagic ? 'hidden' : 'password';
        f.password.required = useMagic ? false : true;
        f.forgotpassword.type = useMagic ? 'hidden' : 'link';

        setForm(f);
        setUseMagic(useMagic);
    }

    return (
        <Animate type="pop">
            <Row title={_t('signin.to') + ' Valetify'}>
                {magicLinkSent && (
                    <div style={{ width: '28em', margin: '0 auto' }}>
                        <Message
                            title={_t('email.check')}
                            type="success"
                            text={_t('email.linkInfo')}
                        />
                    </div>
                )}
                <Card restrictWidth center>
                    {useMagic ? (
                        <Button
                            text={_t('password.use')}
                            color="blue"
                            icon="shield"
                            iconColor="white"
                            className="mx-auto mb-5"
                            rounded
                            iconButton
                            small
                            action={() => {
                                toggleMagicLink(false);
                            }}
                        />
                    ) : (
                        <Button
                            text={_t('magic.useInstead')}
                            color="blue"
                            icon="star"
                            iconColor="white"
                            className="mx-auto mb-5"
                            rounded
                            iconButton
                            small
                            action={() => {
                                toggleMagicLink(true);
                            }}
                        />
                    )}

                    <Form
                        data={form}
                        method="POST"
                        url={useMagic ? '/api/auth/magic' : '/api/auth'}
                        buttonText={useMagic ? _t('magic.send') : _t('signin.label')}
                        auth={false}
                        callback={(res) => {
                            useMagic
                                ? setMagicLinkSent(true)
                                : res.data['2fa_required']
                                ? navigate(`/signin/otp?token=${res.data.token}`)
                                : navigate(authContext.signin(res));
                        }}
                    />

                    <div className="mt-4">
                        {_t('organization.dont')} <Link url="/signup" text={_t('signup.label')} />
                    </div>
                </Card>
            </Row>
        </Animate>
    );
}
