import React, { useEffect } from 'react';
import Style from './details.tailwind';
import TicketHistory from './history';
import TicketAttachment from './media';
import TicketFile from './file';
import TicketDetailsNav from './nav';
import TicketGeneralInfo from './general';
import TicketOwner from './owner';
import TicketCommands from './commands';
import { useLiveQuery } from 'components/hooks/websocket';
import { useSearchParams } from 'react-router-dom';
import { findTicketStatus } from 'utilities/tickets';
import { useProjectContext } from 'context/ProjectContext';

export const TicketDetails = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { switchProject, selectedProject, projects } = useProjectContext();

    const { data } = useLiveQuery('tickets', {
        number: searchParams.get('id'),
        ticket_category_id: searchParams.get('c'),
        user_id: null // Make sure that we are not filtering by user_id
    });

    // HACK: On sync, a ticket with another number is returned
    const ticket = data ? data[0]?.number === searchParams.get('id') ? data[0] : null : null;

    useEffect(() => {
        // update params and switch project if ticket is not in current project
        if (ticket && ticket.ticket_category.project_id !== selectedProject?.id) {
            searchParams.set(
                'p',
                projects.find((item) => item.id === ticket.ticket_category.project_id).name,
            );
            setSearchParams(searchParams);
            switchProject(ticket.ticket_category.project_id);
        }
    }, [selectedProject, ticket]);

    if (!ticket) return null;

    // Separate media with image_id and those without
    const medias = ticket.attachments.reduce(
        (acc, media) => {
            if (media.image_id) {
                acc.withImage.push(media);
            } else {
                acc.withFile.push(media);
            }
            return acc;
        },
        { withImage: [], withFile: [] },
    );

    return (
        <div className={Style.container}>
            <div className={Style.main}>
                <TicketDetailsNav ticket={ticket} />
                <div className={Style.titleContent}>
                    <h3 className={Style.title}>{ticket.subject}</h3>
                </div>

                <TicketAttachment medias={medias.withImage} />
                <TicketFile medias={medias.withFile} />
                <TicketHistory ticket={ticket} />
            </div>
            <div className={Style.side}>
                <TicketGeneralInfo ticket={ticket} status={findTicketStatus(ticket)} />
                <hr className={Style.hr} />
                <TicketOwner ticket={ticket} />
                <hr className={Style.hr} />
                <TicketCommands ticket={ticket} />
            </div>
        </div>
    );
};
