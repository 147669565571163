import React from 'react';
import { Board } from './statuses/board/board';
import { useLiveQuery } from 'components/hooks/websocket';
import { useParams } from 'react-router-dom';
import { useProjectContext, Loader, TitleRow, Animate } from 'components/lib';
import { useMutation } from 'react-query';
import { useApiContext } from 'app/api';
import { useTranslate } from 'app/translations';

const TicketsCategoryStatus = () => {
    const { id } = useParams();
    const { selectedProject } = useProjectContext();
    const { callApi } = useApiContext();
    const { _t } = useTranslate();

    const { data, isLoading } = useLiveQuery(
        'ticket_category',
        { id, project_id: selectedProject?.id },
        {
            enabled: !!selectedProject?.id && !!id,
        },
    );

    const statusTemplate = data ? data[0]?.status_template : [];

    const updateTicketStatus = async (status) => {
        const { data } = await callApi({
            method: 'PATCH',
            url: `/api/ticket/category/${id}`,
            data: {
                ...status,
                project_id: selectedProject?.id,
            },
        });
        return data;
    };

    const updateTicketStatusMutation = useMutation(updateTicketStatus);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Animate>
            <TitleRow title={_t('status.multiple')} />
            {statusTemplate && (
                <Board
                    statusTemplate={statusTemplate}
                    updateTicketStatusMutation={updateTicketStatusMutation}
                />
            )}
        </Animate>
    );
};

export default TicketsCategoryStatus;
