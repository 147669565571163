import React, { useState, useContext, useEffect } from "react";
import { Button, ViewContext, TicketForm } from "components/lib";
import Style from "./controls.tailwind";
import { useTranslate } from "app/translations";
import { useLiveQuery } from "components/hooks/websocket";
import { useProjectContext } from "components/lib";
import { AuthContext } from "app/auth";

export const TicketControls = ({ setSearchParams, searchParams, getSearchParams }) => {
	const { _t } = useTranslate();
	const viewContext = useContext(ViewContext);
	const [searchValue, setSearchValue] = useState(searchParams.get("q") || "");
	const {
		user: { permission },
	} = useContext(AuthContext);

	const { projectUsers, selectedProject, isAllProjectSelected, projectUser } = useProjectContext();
	const categories = useLiveQuery(
		"ticket_categories",
		{ project_id: selectedProject?.id },
		{
			enabled: !!selectedProject?.id,
		},
	);

	const currCategory = categories.data?.find((category) => {
		return category.id === searchParams.get("c");
	});

	const updateFilterParams = (params) => {
		let newParams = getSearchParams();

		newParams = { ...newParams, ...params };

		// Remove empty params
		Object.keys(newParams).forEach((key) => {
			if (!newParams[key]) {
				delete newParams[key];
			}
		});

		setSearchParams(newParams);
	};

	const sortParamsLength = Object.keys(getSearchParams()).filter((key) => {
		return key === "by";
	}).length;

	const filterParamsLength = Object.keys(getSearchParams()).filter((key) => {
		return key === "priority" || key === "assignee" || key === "me" || key === "creator";
	}).length;

	const onSearchValueChange = (e) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			updateFilterParams({ q: searchValue });
		}, 500);

		return () => clearTimeout(delayDebounceFn);
	}, [searchValue]);

	const categoryAssignees = currCategory?.integration_data?.users || [];

	return (
		<section className={Style.container}>
			<div className={Style.actions}>
				<Button
					text={_t("sorts")}
					small
					icon="sliders"
					iconColor="white"
					iconButton
					count={sortParamsLength}
					color="blue"
					className={"px-[15px] py-[7px]"}
					action={() => {
						viewContext.modal.show(
							{
								title: _t("ticket.sort.title"),
								form: {
									by: {
										type: "select",
										label: _t("ticket.filter.by.label"),
										required: false,
										options: [
											{
												label: _t("ticket.filter.by.date_created"),
												value: "date_created",
											},
											{
												label: _t("ticket.filter.by.due_date"),
												value: "due_date",
											},
											{
												label: _t("ticket.filter.by.priority"),
												value: "priority",
											},
										],
										default: searchParams.get("by") || "date_created",
									},
									order: {
										type: "select",
										label: _t("ticket.filter.order.label"),
										required: true,
										options: [
											{ label: _t("ticket.filter.order.asc"), value: "asc" },
											{
												label: _t("ticket.filter.order.desc"),
												value: "desc",
											},
										],
										default: searchParams.get("order") || "asc",
									},
								},
								buttonText: _t("save"),
							},
							(data) => {
								updateFilterParams(data);
							},
						);
					}}
				/>
				<Button
					text={_t("filters")}
					small
					color="blue"
					icon="filter"
					iconColor="white"
					iconButton
					count={filterParamsLength}
					className={"px-[15px] py-[7px]"}
					action={() => {
						viewContext.modal.show(
							{
								title: _t("ticket.filter.title"),
								form: {
									priority: {
										type: "select",
										label: _t("priority.label"),
										options: [
											{ label: _t("none"), value: "" },
											{ label: _t("priority.low"), value: "low" },
											{ label: _t("priority.medium"), value: "medium" },
											{ label: _t("priority.high"), value: "high" },
											{ label: _t("priority.urgent"), value: "urgent" },
										],
										default: searchParams.get("priority"),
									},
									...(permission !== "contact" &&
										!isAllProjectSelected && {
											assignee: {
												type: "select",
												label: _t("assignee.one"),
												options: [
													{ label: _t("none"), value: "" },
													...categoryAssignees.map((user) => {
														return {
															label: user.name,
															value: user.email,
														};
													}),
												],
												default: searchParams.get("assignee"),
											},
										}),
									...(projectUser?.role !== "contact" && {
										me: {
											type: "checkbox",
											options: ["yes"],
											label: _t("ticket.creator.me"),
											default: [searchParams.get("me")],
										},
									}),
									...(projectUser?.role !== "contact" && {
										creator: {
											type: "select",
											label: _t("ticket.creator.one"),
											options: [
												{ label: _t("none"), value: "" },
												...projectUsers.map((user) => {
													return {
														label: user.first_name + " " + user.last_name + " (" + user.email + ")",
														value: user.email,
													};
												}),
											],
											default: searchParams.get("creator"),
										},
									}),
								},
								buttonText: _t("save"),
							},
							(data) => {
								//HACK: Sorry temporary hack since the code structure will need a big refactoring
								// Remove ['null'] from me
								if (data.me && data.me[0] === "null") {
									data.me = undefined;
								}
								// remove empty values like null in me array
								data.me = data.me?.filter((val) => val !== null);
								
								updateFilterParams(data);
							},
						);
					}}
				/>
				{selectedProject && (
					<Button
						text={_t("ticket.create.title")}
						small
						className={"px-[15px] py-[7px]"}
						action={() => {
							viewContext.modal.show({
								title: _t("ticket.create.title"),
								component: (
									<TicketForm
										cancel
										categories={categories.data}
										category={searchParams.get("c")}
									/>
								),
							});
						}}
					/>
				)}
			</div>
			{/** Search input */}
			<input
				type="text"
				placeholder={_t("search")}
				className="border border-gray-200 rounded-full px-4 py-2 max-w-xs w-full"
				value={searchValue}
				onChange={onSearchValueChange}
			/>
		</section>
	);
};
