import React, { useState, useRef, useContext } from 'react';
import Style from './chat.tailwind';
import { useApiContext } from 'app/api';
import { FileInput, Loader } from 'components/lib';
import { FilesViewer } from './filesViewer';
import { useTranslate } from 'app/translations';
import { useQueryClient } from 'react-query';
import { Icon, ViewContext } from 'components/lib';

export const Chat = (props) => {
    const { _t } = useTranslate();
    const viewContext = useContext(ViewContext);
    const { ticket } = props;
    const { integration_data } = ticket;
    const queryClient = useQueryClient();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const service = ticket?.ticket_category?.integration_data?.service;
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [message, setMessage] = useState('');
    const { callApi } = useApiContext();

    const fileInputRef = useRef(null);

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        let formData = new FormData();

        const action_type = message.trim() === '' ? 'attachment_added' : 'comment_posted';

        formData.append('comment', message);
        formData.append('ticket_id', ticket.id);
        formData.append('action_type', action_type);
        formData.append('fromValetify', true);
        formData.append(
            'integration_data',
            JSON.stringify({
                ...ticket?.ticket_category?.integration_data,
                task_id: integration_data.task,
            }),
        );

        // Append each file separately
        uploadedFiles.forEach((file, index) => {
            formData.append(`file${index}`, file.data);
        });

        const res = await callApi({
            url: `/api/ticket/history/comment?service=${service}&ticket=${ticket.id}`,
            method: 'post',
            data: formData,
        });

        if (res.status === 200) {
            queryClient.refetchQueries('tickets');
        } else {
            viewContext.notification.show(res.message, 'error', true);
        }
        setIsSubmitting(false);
        setMessage('');
        setUploadedFiles([]);
        resetFileInput();
    };

    const removeFile = (indexToRemove) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file, index) => index !== indexToRemove));
    };
    const resetFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };
    React.useEffect(() => {
        return () => {
            resetFileInput();
        };
    }, []);
    const handleFileChange = (name, file, valid) => {
        if (valid === false) return;
        setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...file]);
    };

    const disableSubmitConditions = () => {
        return (uploadedFiles.length === 0 && message.trim() === '') || isSubmitting;
    };

    return (
        <div className={Style.container}>
            <FileInput
                onChange={handleFileChange}
                ref={fileInputRef}
                classes={'absolute invisible'}
            />

            <form onSubmit={handleSubmit} className="px-5 py-3 relative">
                <div className="flex -mx-3">
                    <div className="w-full px-3 relative">
                        <textarea
                            onKeyDown={handleKeyDown}
                            className={Style.textArea}
                            rows={15}
                            cols={2}
                            placeholder={_t('ticket.history.writeComment')}
                            onChange={handleChange}
                            value={message}
                            disabled={isSubmitting}
                        />
                        {isSubmitting && (
                            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 opacity-50">
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex justify-between px-3 mt-1 -mx-3 gap-3">
                    {uploadedFiles.length > 0 && (
                        <FilesViewer files={uploadedFiles} onRemoveFile={removeFile} />
                    )}
                    <div className="flex gap-1 ml-auto">
                        <button
                            type="button"
                            className={Style.submitBtn}
                            onClick={() => fileInputRef.current.click()}
                            disabled={isSubmitting}>
                            <Icon image={'paperclip'} color={'white'} size={18} />
                        </button>
                        <button
                            type="submit"
                            className={Style.submitBtn}
                            disabled={disableSubmitConditions()}
                            style={{
                                cursor: disableSubmitConditions() ? 'not-allowed' : 'pointer',
                                backgroundColor: disableSubmitConditions() ? 'grey' : '',
                                pointerEvents: disableSubmitConditions() ? 'none' : 'auto',
                            }}>
                            {_t('ticket.history.send')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
