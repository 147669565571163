import React, { useRef, useContext, useEffect } from 'react';
import Style from './sidebar.tailwind';
import { useTranslate } from 'app/translations';
import { useSearchParams } from 'react-router-dom';
import { Button, useAttach, ViewContext, Icon } from 'components/lib';
import { useQuery } from 'react-query';
import { useApiContext } from 'app/api';
import Dropdown from 'components/dropdown/dropdown';
import integrationsConfig from 'config/integrations';
import { useProjectContext } from 'components/lib';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'app/auth';
import { findActiveTickets } from "utilities/tickets";

export const TicketSidebar = (props) => {
    const { categories, tickets } = props;
    let [searchParams] = useSearchParams();
    const categoryParams = searchParams.get('c');
    const { _t } = useTranslate();
    const { callApi } = useApiContext();
    const createRef = useRef(null);
    const importRef = useRef(null);
    const viewContext = useContext(ViewContext);
    const authContext = useContext(AuthContext);
    const {
        user: { permission },
    } = authContext;
    const { selectedProject } = useProjectContext();
    const navigate = useNavigate();

    useEffect(() => {
        categories.refetch();
    }, [selectedProject]);

    const integrations = useQuery('integrations', () => {
        return callApi({ url: '/api/integration', permission: 'admin' });
    });

    const integrationData = integrations.data?.data || [];

    // filter integrations with unique service name
    const integrationsDataType = integrationData.filter(
        (integration, index, self) =>
            index === self.findIndex((t) => t.service_name === integration.service_name),
    );

    let createList = [];
    let importList = [];

    integrationsDataType?.forEach((integration) => {
        const integrationConfig = integrationsConfig[integration.service_name];

        const categoryConfig = integrationConfig?.ticket?.category;
        if(!categoryConfig) return;
        if (categoryConfig.create) {
            createList.push({
                label: categoryConfig.create.label,
                action: () => {
                    viewContext.modal.show(
                        {
                            title: _t('categories.create'),
                            component: <categoryConfig.create.connector />,
                        },
                        () => {
                            categories.refetch();
                        },
                    );
                },
            });

            createList.push({
                label: categoryConfig.clone.label,
                action: () => {
                    viewContext.modal.show(
                        {
                            title: _t('categories.existing.createFrom'),
                            component: <categoryConfig.clone.connector />,
                        },
                        () => {
                            categories.refetch();
                        },
                    );
                },
            });
        }

        if (categoryConfig.import) {
            importList.push({
                label: categoryConfig.import.label,
                action: () => {
                    viewContext.modal.show(
                        {
                            title: _t('categories.import'),
                            component: <categoryConfig.import.connector />,
                        },
                        () => {
                            categories.refetch();
                        },
                    );
                },
            });
        }
    });

    const { Component: CreateDropdown, toggle: createToggle } = useAttach({
        ref: createRef,
        position: 'bottom',
        offset: 5,
        children: <Dropdown list={createList} />,
    });

    const { Component: ImportDropdown, toggle: importToggle } = useAttach({
        ref: importRef,
        position: 'bottom',
        offset: 5,
        children: <Dropdown list={importList} />,
    });

    const renderCategory = (category) => {
        const activeTickets =  findActiveTickets(tickets);
        const isSelected = categoryParams === category.id;
        if(category.id === 'all') {
            // get all tickets count
            const allTicketsCount = activeTickets.length;
            category.ticket_count = allTicketsCount;
        } else {
            // get category tickets count
            const categoryTickets = activeTickets.filter(ticket => ticket.ticket_category_id === category.id);

            category.ticket_count = categoryTickets.length;
        }
        return (
            <div
                key={category.id}
                className={`${Style.category} ${
                    isSelected ? 'flex bg-main-300/10 text-main-400' : ''
                }`}>
                <button
                    onClick={() => {
                        props.updateSearchParams('c', category.id);
                    }}
                    className="w-full text-left">
                    {category.name}
                </button>
                <span className={Style.count}>{category?.ticket_count}</span>
                {category.id !== 'all' && ['admin', 'owner'].includes(permission) && (
                    <Icon
                        image="edit"
                        className={Style.icon}
                        size={17}
                        onClick={() => {
                            navigate(`/app/tickets/category/${category.id}`);
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <h2 className={Style.title}>{_t('categories.label.multiple')}</h2>
            </div>
            <div className={Style.categoryList}>
                {renderCategory({
                    id: 'all',
                    name: _t('all'),
                })}
                {categories.data?.map((category) => {
                    return renderCategory(category);
                })}
            </div>
            {['admin', 'owner'].includes(permission) && (
                <div className={Style.btnContent}>
                    <div ref={createRef} className="relative">
                        <Button
                            text={_t('createFrom')}
                            small
                            className={Style.btn}
                            action={() => {
                                createToggle();
                            }}
                        />
                        <CreateDropdown />
                    </div>
                    <div ref={importRef} className="relative">
                        <Button
                            text={_t('categories.import')}
                            small
                            color="blue"
                            className={Style.btn}
                            action={() => {
                                importToggle();
                            }}
                        />
                        <ImportDropdown />
                    </div>
                </div>
            )}
        </div>
    );
};
