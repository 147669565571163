/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import { SubNav } from 'components/lib';
import { useTranslate } from 'app/translations.js';

export function AccountNav(props) {
    const { _t } = useTranslate();

    const items = [
        { link: '/app/settings/organization', label: _t('organization.label'), permission: 'owner' },
        { link: '/app/settings/profile', label: _t('profile.label') },
        { link: '/app/settings/projects', label: _t('project.label.multiple'), permission: 'admin' },
        { link: '/app/settings/password', label: _t('password.label') },
        // { link: '/app/settings/2fa', label: '2FA' },
        // { link: '/app/settings/billing', label: _t('billing.label'), permission: 'owner' },
        // { link: '/app/settings/apikeys/', label: _t('api.key.multiple'), permission: 'developer' },
        {
            link: '/app/settings/users',
            label: _t('user.label.multiple'),
            permission: 'admin',
        }
    ]

    return (
        <SubNav items={items} />
    );
}
