/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import { useState, createContext } from 'react';
import {
    AppLayout,
    AuthLayout,
    HomeLayout,
    OnboardingLayout,
    Modal,
    Notification,
    Loader,
    useNavigate,
    Pannel
} from '../lib';
import { useTranslate } from 'app/translations';
import { navigateRoot } from 'utilities/routes';
// import './scss/normalize.scss';
// import './scss/view.scss';
// import './scss/typography.scss';

export const ViewContext = createContext();

export function View(props) {
    const { _t } = useTranslate();
    const navigate = useNavigate();

    // state
    const [notification, setNotification] = useState({ visible: 'hide', autoclose: true });
    const [modal, setModal] = useState({});
    const [pannel, setPannel] = useState({});
    const [loading, setLoading] = useState(false);

    // layouts
    const layouts = {
        app: AppLayout,
        home: HomeLayout,
        auth: AuthLayout,
        onboarding: OnboardingLayout,
    };

    // set title & layout
    document.title = props.title;
    let Layout = props.layout ? layouts[props.layout] : AppLayout;

    if (!props.display) return false;

    function showNotification(text, type, autoclose, format, icon) {
        setNotification({
            text: text,
            type: type,
            show: true,
            format: format,
            icon: icon,
            autoclose: autoclose,
        });

        setTimeout(hideNotification, 2000);
    }

    function hideNotification() {
        setNotification({
            text: '',
            type: '',
            show: false,
            format: null,
            icon: null,
            autoclose: true,
        });
    }

    function showModal(content, callback) {
        let data = { ...modal };

        if (content) {
            for (let key in content) data[key] = content[key];

            data.show = true;
            data.callback = callback;
        }

        setModal(data);
    }

    function hideModal(cancel, res) {
        // callback?
        if (!cancel && modal.callback) modal.callback(modal.form, res);

        // reset the modal
        setModal({
            title: null,
            text: null,
            buttonText: null,
            url: null,
            method: null,
            show: false,
            headers: null,
            width: null,
        });
    }

    function showPannel(content, callback) {
        let data = { ...pannel };
        if (content) {
            for (let key in content) data[key] = content[key];

            data.show = true;
            data.callback = callback;
        }
        setPannel(data);
    }

    function hidePannel(cancel, res) {
        if (pannel.callback) pannel.callback(pannel.form, res);

        // reset the pannel
        setPannel({
            show: false,
        });
    }

    function handleError(err) {
        let message = _t('message.matrix');

        if (err) {
            message = err?.message.toString() || err?.data?.message.toString();

            if (err) {
                if (err.data?.message) message = err.data.message;

                if (err.status) {
                    switch (err.status) {
                        case 500:
                            // if in development, show the error else show a generic message
                            if (process.env.NODE_ENV === 'development') {
                                showNotification(message, 'error', false);
                            } else {
                                showNotification(_t('message.matrix'), 'error', false);
                            }
                            break;
                        case 401:
                            navigateRoot('/signin');
                            break;

                        case 404:
                            navigateRoot('/notfound');
                            break;

                        case 429:
                            showNotification(err.data, 'error', false);
                            break;

                        case 402: // payment required
                            navigate('/app/settings/upgrade?plan=' + err.data.plan);
                            break;

                        default:
                            showNotification(message, 'error', false);
                            break;
                    }
                }
            }
        }
    }

    const context = {
        notification: {
            show: showNotification,
            hide: hideNotification,
            data: notification,
        },
        modal: {
            show: showModal,
            hide: hideModal,
            data: modal,
        },

        pannel: {
            show: showPannel,
            hide: hidePannel,
            data: pannel,
        },

        setLoading: (state) => setLoading(state),
        handleError: handleError,
    };

    return (
        <ViewContext.Provider value={context}>
            {notification.show && <Notification {...notification} />}

            {loading && <Loader fullScreen />}

            {modal.show && <Modal {...modal} />}

            {pannel.show && <Pannel {...pannel} />}

            <Layout title={props.title} data={props.data}>
                {props.display}
            </Layout>
        </ViewContext.Provider>
    );
}
