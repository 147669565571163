import React, { useContext } from 'react';
import { Form, TitleRow } from 'components/lib';
import { useTranslate } from 'app/translations';
import { ViewContext } from "components/lib";
import Style from './settings.tailwind';

export const StatusSettings = (props) => {
    const { statusTemplate, updateTicketStatusMutation, columnId } = props;
    const viewContext = useContext(ViewContext);
    const { _t } = useTranslate();

    const index = statusTemplate.findIndex(status => status.key === columnId);
    const defaultValue = statusTemplate[index].send_email ? 'Yes' : 'No';
 
    const formData = {
        send_email: {
            label: _t('table.actions.mail'),
            type: 'radio',
            options: ['yes', 'no'],
            default: defaultValue.toLocaleLowerCase(),
            required: true,
        },
    };

    const mutateData = (data) => {
        statusTemplate[index].send_email = data.send_email === 'Yes' ? true : false;
        updateTicketStatusMutation.mutate({ status_template: statusTemplate });
    }

    return (
        <div className={Style.container}>
            <div className={Style.header}>
                <TitleRow title={`${_t('settings.label')}: ${props.title}`} />
            </div>
            <div className={Style.main}>
                <div className={Style.emails}>
                    <h1 className={Style.subTitle}>Emails</h1>
                    <Form
                        data={formData}
                        callback={(data) => {
                            if(data.send_email === defaultValue) {
                                viewContext.pannel.hide(true);
                            }
                            viewContext.pannel.hide(true);
                            mutateData(data);
                        }}
                        buttonText={_t('save')}
                    />
                </div>
            
            </div>
        </div>
    );
};