import React, { useContext } from 'react';
import axios from 'axios';
import { _t } from './translations';
import { getUserCache, deleteUserCookie } from 'utilities/cache';
import { getSubdomain } from 'utilities/routes';
import settings from '../settings';
import { navigateRoot } from 'utilities/routes';

const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {
    const baseURL = settings.server_url;
    const baseHeaders = {
        accept: 'application/json',
        'Account-Domain': getSubdomain(),
    };

    const callApi = async (params) => {
        try {
            let { method = 'get', url, data, headers = {}, auth = true } = params;
            
            const user = getUserCache();
            if (auth) {

                if (!user || user === 'undefined') {
                    console.error(_t('authorization.header.empty'));
                }

                const accountDomain = user?.accounts?.find(
                    (account) => account.id === user.account_id,
                ).domain;
                // if there is a token, add it to the headers
                if (user?.token) {
                    headers = {
                        ...headers,
                        Authorization: `Bearer ${user.token}`,
                        'Account-Domain': accountDomain || null,
                    };
                } else {
                    console.error(_t('authorization.header.empty'));
                }
            }
            // Setup the axios options
            const options = {
                method: method,
                url: baseURL + url,
                data,
                headers: {
                    ...baseHeaders,
                    ...headers,
                    'accept-language': user?.language || window.navigator.language,
                },
            };

            // Call the API
            const response = await axios(options);
            // return the data
            return {
                data: response.data.data || response.data,
                status: response.status,
            };
        } catch (error) {
            // check if jwt expired
            if (error.response?.data?.message === 'jwt expired') {
                callApi({ method: 'delete', url: '/api/auth' });
                localStorage.clear();
                // Clear the user cookie
                deleteUserCookie();
                navigateRoot('/signin');
            }

            console.error(error.response?.data?.message || error.message)

            if (error.response) {
                return {
                    data: [],
                    status: error.response.status,
                    message: error.response.data?.message || _t(`error.status.${error.response.status}`)
                }
            }
            return error;
        }
    };

    const postEvent = async (name, metadata) => {
        callApi({
            method: 'post',
            url: '/api/event',
            data: {
                name: name,
                metadata: metadata,
            },
            auth: false,
        });
    };

    return <ApiContext.Provider value={{ callApi, postEvent }}>{children}</ApiContext.Provider>;
};
// make sure use
export const useApiContext = () => {
    return useContext(ApiContext);
};
