import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Form, ViewContext } from 'components/lib';
import integrationsConfig from 'config/integrations';
import { useTranslate } from 'app/translations';
import { useNavigate } from 'react-router-dom';
import Style from './ticketsCategory.tailwind';
import { useMutation } from 'react-query';
import { useApiContext } from 'app/api';
import Papa from 'papaparse';

const TicketsCategory = (props) => {
    const { id } = useParams();
    const { _t } = useTranslate();
    const navigate = useNavigate();
    const viewContext = useContext(ViewContext);
    const { callApi } = useApiContext();
    const [csvJSON, setCsvJSON] = useState();
    const [selectedFile, setSelectedFile] = useState();

    const { mutate: importCSV } = useMutation(
        () => {
            return callApi({
                url: `/api/tickets/import?category_id=${id}`,
                method: 'POST',
                data: {
                    data: csvJSON,
                },
            });
        },
        {
            onSuccess: (res) => {
                const { data } = res;

                const formatPlural = (count, singular, plural) => {
                    return count <= 1 ? `${count} ${singular}` : `${count} ${plural}`;
                };

                const importedText = `${formatPlural(
                    data.imported,
                    _t('ticket.has'),
                    _t('ticket.have'),
                )} ${_t('import.been')},`;

                const existingText =
                    data.existing > 0
                        ? `${formatPlural(data.existing, 'ticket', 'tickets')} ${_t(
                              'alreadyExist',
                          )},`
                        : '';
                const ignoredText =
                    data.ignored > 0
                        ? `${formatPlural(data.ignored, 'ticket', 'tickets')} ${_t('ignored')},`
                        : '';

                const message = `${importedText} ${existingText} ${ignoredText}`;

                if (res.status === 200) {
                    viewContext.notification.show(message, 'success', true, 'toast');
                }
            },
        },
    );

    const onDelete = () => {
        viewContext.modal.show(
            {
                title: _t('ticket.category.delete'),
                form: {},
                buttonText: _t('delete'),
                url: `/api/ticket/category/${id}`,
                method: 'delete',
                destructive: true,
            },
            () => {
                navigate('/app/tickets');
            },
        );
    };

    const integration = props.data?.integration_data;
    const IntegrationForm =
        integrationsConfig[integration?.service]?.ticket.category.general.integration;

    let formData = {
        name: {
            label: _t('ticket.category.name'),
            type: 'text',
            value: props.data?.name,
            required: true,
        },
    };

    if (!integration) return null;

    return (
        <>
            <div className={Style.container}>
                <Card restrictWidth className="w-full" title={_t('ticket.category.info')}>
                    <Form
                        data={formData}
                        buttonText={_t('save')}
                        method="PATCH"
                        url={`/api/ticket/category/${id}`}
                        injectedData={{ project_id: props.data?.project_id }}
                    />
                </Card>
                <Card restrictWidth className="w-full" title={_t('ticket.category.integration')}>
                    <div className="mb-10 flex flex-col">
                        <span className="text-md font-bold">
                            {_t('import.ticket.multiple')} (CSV)
                        </span>
                        <form className="space-y-4">
                            <input
                                type="file"
                                name="file"
                                id="custom-input"
                                hidden
                                accept=".csv"
                                onChange={(e) => {
                                    const files = e.target.files;
                                    setSelectedFile(files[0].name);
                                    if (files) {
                                        Papa.parse(files[0], {
                                            complete: function (results) {
                                                setCsvJSON(results.data);
                                            },
                                        });
                                    }
                                }}
                            />
                            <label
                                for="custom-input"
                                className="block text-sm text-slate-500 mr-4 py-2 px-4
            rounded-md border border-main-300 font-semibold bg-main-50
            text-main-700 hover:bg-main-100/50 cursor-pointer">
                                {_t('import.select')}
                            </label>

                            <label className="text-sm text-slate-500">{selectedFile}</label>
                            <Button
                                type="submit"
                                small
                                color="blue"
                                action={(e) => {
                                    e?.preventDefault();
                                    importCSV();
                                }}
                                text={_t('import.label')}></Button>
                        </form>
                        <a className='mt-2' href="/assets/example.csv" download>{_t('download.example')}</a>
                       
                    </div>
                    <IntegrationForm
                        data={integration}
                        method="PATCH"
                        url="/api/ticket/category"
                        progressive={false}
                    />
                </Card>
            </div>

            <Button text={_t('delete')} color="red" action={onDelete} />
        </>
    );
};

export default TicketsCategory;
